import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";
import { removeStorage } from "../../@utility/app/storage";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const loadDepositFirst = async () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    let config = {
        method: svConfig.method_get,
        url: '/customer/payment/deposit/channel',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: {}
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const confirmDeposit = async (data) => {
    const {
        amount,
        bankId,
        typePage,
        channel,
        channelId,
        channelMode
    } = data;

    let methodType = 4;
    let selectType = 1;

    if (typePage === "qrcode" ||
        typePage === "akpay" ||
        typePage === "dypay" ||
        typePage === "payin" ||
        typePage === "payin-auto" ||
        typePage === "tpay" ||
        typePage === "tpay-auto" ||
        typePage === "7pay" ||
        typePage === "bitpayz" ||
        typePage === "bitpayz-auto" ||
        typePage === "gpay" ||
        typePage === "gpay-auto") {
        methodType = 5;
        selectType = 3;

        if (channelMode === "account") {
            selectType = 1;
        }
    }
    else if (typePage === "truemoney") {
        methodType = 6;
        selectType = 2;
    }
    else if (typePage === "bank") {
        methodType = 4;
    }
    else {
        methodType = 0;
    }

    const dataJson = {
        "amount": amount,
        "bankId": bankId,
        "method": methodType,
        channel,
        channelId,
        "select": selectType
    };

    removeStorage("paySrc");
    removeStorage("payMode");
    removeStorage("payAccount");
    removeStorage("payTimeOut");
    removeStorage("processDeposit");
    removeStorage("depositPromptPayTime");

    let config = {
        method: svConfig.method_post,
        url: "/customer/payment/deposit/channel-v2",
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(dataJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const confirmStep = async (data) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    const {
        td_id,
        promotion_status,
        promotion_condition_id
    } = data;

    let myJson = {
        "td_id": td_id,
        "promotion_status": promotion_status,
        "promotion_condition_id": promotion_condition_id
    };

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/confirm',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const confirmPaySlip = async (data) => {
    if (process.env.REACT_APP_PAYMENT_V2 === "true" || process.env.REACT_APP_PAYMENT_V2 === true) {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL_V2;
    }
    else {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    }

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/slip',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: data
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const confirmUploadCheckSlip = async (data) => {
    if (process.env.REACT_APP_PAYMENT_V2 === "true" || process.env.REACT_APP_PAYMENT_V2 === true) {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL_V2;
    }
    else {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    }

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/verify-slip',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: data
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result, message } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code,
            message: message
        };
    }
}

const checkDeposit = async (depositId) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    let myJson = {
        "id": depositId
    };

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/check',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const cancenDeposit = async () => {
    if (process.env.REACT_APP_PAYMENT_V2 === "true" || process.env.REACT_APP_PAYMENT_V2 === true) {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL_V2;
    }
    else {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    }

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/cancel',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: {}
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const getBankAgent = async (bankId) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    let myJson = {
        "bankId": bankId
    };

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/bank/agent',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const getBankCustomer = async (bankId) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    let myJson = {
        "bankId": bankId
    };

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/bank/customer',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

export {
    loadDepositFirst,
    confirmDeposit,
    confirmStep,
    confirmPaySlip,
    checkDeposit,
    cancenDeposit,
    getBankAgent,
    getBankCustomer,
    confirmUploadCheckSlip
};